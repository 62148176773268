import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import ArticleCard from '../../components/blog/ArticleCard';
import { articles, categories } from '../../data/blogData';

const CategoryPage = () => {
  const { slug } = useParams();
  const category = categories.find(cat => cat.slug === slug);
  
  if (!category) {
    return <div>Catégorie non trouvée</div>;
  }

  const categoryArticles = articles.filter(article => 
    article.categories.includes(category.id)
  );

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>{`${category.name} - Articles et conseils | Blog Colis Facile`}</title>
        <meta 
          name="description" 
          content={`Découvrez nos articles sur ${category.name.toLowerCase()}. ${category.description}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": category.name,
            "description": category.description,
            "publisher": {
              "@type": "Organization",
              "name": "Colis Facile"
            }
          })}
        </script>
      </Helmet>

      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <Link to="/blog" className="text-primary hover:underline mb-4 inline-block">
            ← Retour au blog
          </Link>
          <h1 className="text-4xl font-bold">{category.name}</h1>
          <p className="text-gray-600 mt-2">{category.description}</p>
        </div>

        {/* Articles de la catégorie */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {categoryArticles.map((article) => (
            <ArticleCard key={article.id} article={article} />
          ))}
        </div>

        {categoryArticles.length === 0 && (
          <p className="text-center text-gray-600">
            Aucun article dans cette catégorie pour le moment.
          </p>
        )}
      </div>
    </div>
  );
};

export default CategoryPage; 