import React from 'react';
import { Link } from 'react-router-dom';
import { Badge } from '../ui/badge';

const ArticleCard = ({ article }) => {
  if (!article) return null;
  
  // Sélectionner le format d'image approprié
  const getImageUrl = (mainImage) => {
    if (!mainImage) return 'https://placehold.co/600x400';
    
    // On préfère le format medium pour les cards
    const baseUrl = process.env.REACT_APP_STRAPI_URL?.replace('/api', '') || 'http://localhost:1337';
    
    if (mainImage.formats?.medium) {
      return `${baseUrl}${mainImage.formats.medium.url}`;
    } else if (mainImage.formats?.small) {
      return `${baseUrl}${mainImage.formats.small.url}`;
    } else {
      return `${baseUrl}${mainImage.url}`;
    }
  };
  
  return (
    <Link to={`/blog/${article.slug}`} className="group">
      <article className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
        <div className="aspect-video relative">
          <img
            src={getImageUrl(article.mainImage)}
            alt={article.title}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="p-6">
          <div className="flex gap-2 mb-3 flex-wrap">
            {article.tags?.map((tag, index) => (
              <Badge key={index} variant="secondary">
                {tag}
              </Badge>
            ))}
          </div>
          <h2 className="text-xl font-semibold mb-2 group-hover:text-primary transition-colors">
            {article.title || 'Sans titre'}
          </h2>
          <p className="text-gray-600 mb-4 line-clamp-2">
            {article.chapo || 'Aucune description disponible'}
          </p>
          <div className="flex justify-between items-center text-sm text-gray-500">
            <span>{article.readingTime} min de lecture</span>
            <time dateTime={article.publishedAt}>
              {article.publishedAt 
                ? new Date(article.publishedAt).toLocaleDateString('fr-FR')
                : 'Date non disponible'}
            </time>
          </div>
        </div>
      </article>
    </Link>
  );
};

export default ArticleCard; 