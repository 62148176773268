import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Layout from './components/Layout';
import HomePage from './pages/index';
import PrivacyPage from './pages/privacy';
import TermsPage from './pages/terms';
import SuiviTransporteur from './pages/SuiviTransporteur';
import Error_404 from './pages/404';
import MentionsLegales from './pages/MentionsLegales';
import SuiviColis from './pages/SuiviColis';
import SearchRelay from './pages/SearchRelay';
import BlogIndex from './pages/blog';
import BlogCategory from './pages/blog/category';
import BlogArticle from './pages/blog/article';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-TD5J5SVL'
}

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <Router>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Colis Facile - Suivi de colis et points relais</title>
        <meta name="description" content="Suivez vos colis et trouvez des points relais facilement avec Colis Facile" />
        <link rel="canonical" href="https://colis-facile.com" />
      </Helmet>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/relay/search" element={<SearchRelay />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/suivi/:carrier" element={<SuiviTransporteur />} />
          <Route path="/suivi" element={<SuiviColis />} />
          <Route path="/legal" element={<MentionsLegales />} />
          <Route path="/blog" element={<BlogIndex />} />
          <Route path="/blog/categorie/:slug" element={<BlogCategory />} />
          <Route path="/blog/:slug" element={<BlogArticle />} />
          <Route path="*" element={<Error_404 />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;