import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Badge } from '../../components/ui/badge';
import { strapiAPI } from '../../lib/strapi';

const ArticlePage = () => {
  const { slug } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fonction pour obtenir l'URL de l'image
  const getImageUrl = (mainImage) => {
    if (!mainImage) return 'https://placehold.co/1200x800';
    
    const baseUrl = process.env.REACT_APP_STRAPI_URL?.replace('/api', '') || 'http://localhost:1337';
    
    if (mainImage.formats?.large) {
      return `${baseUrl}${mainImage.formats.large.url}`;
    } else if (mainImage.formats?.medium) {
      return `${baseUrl}${mainImage.formats.medium.url}`;
    } else {
      return `${baseUrl}${mainImage.url}`;
    }
  };

  // Fonction pour formater le contenu
  const formatContent = (content) => {
    if (!content) return '';
    if (typeof content === 'string') return content;
    
    return content.map(block => {
      if (block.children && block.children.length > 0) {
        return block.children.map(child => child.text).join('\n');
      }
      return '';
    }).join('\n');
  };

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await strapiAPI.get(`/articles`, {
          params: {
            filters: {
              slug: {
                $eq: slug
              }
            },
            populate: '*'
          }
        });
        
        if (response.data.data.length > 0) {
          setArticle(response.data.data[0]);
          console.log('Article data:', response.data.data[0]); // Pour déboguer
        } else {
          setError('Article non trouvé');
        }
      } catch (err) {
        setError('Erreur lors du chargement de l\'article');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);

  if (loading) return <div className="container mx-auto px-4 py-8">Chargement...</div>;
  if (error) return <div className="container mx-auto px-4 py-8 text-red-500">{error}</div>;
  if (!article) return <div className="container mx-auto px-4 py-8">Article non trouvé</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>{`${article.title} | Blog Colis Facile`}</title>
        <meta name="description" content={article.chapo} />
      </Helmet>

      <article className="max-w-4xl mx-auto">
        <header className="mb-8">
          <Link to="/blog" className="text-primary hover:underline mb-4 inline-flex items-center">
            ← Retour aux articles
          </Link>

          <div className="flex gap-2 mb-4 flex-wrap">
            {article.tags?.map((tag, index) => (
              <Badge key={index} variant="secondary">
                {tag}
              </Badge>
            ))}
          </div>

          <h1 className="text-4xl font-bold mb-4">{article.title}</h1>
          <p className="text-xl text-gray-600 mb-4">{article.chapo}</p>

          <div className="flex justify-between items-center text-sm text-gray-500 mb-4">
            <div className="flex items-center gap-2">
              <span>{article.readingTime} min de lecture</span>
            </div>
            <time dateTime={article.publishedAt}>
              {new Date(article.publishedAt).toLocaleDateString('fr-FR')}
            </time>
          </div>
        </header>

        {article.mainImage && (
          console.log(article.mainImage),
          <div className="mb-8">
            <img
              src={getImageUrl(article.mainImage)}
              alt={article.mainImage.alternativeText || article.title}
              className="w-full h-[400px] object-cover rounded-lg"
            />
          </div>
        )} 
        {console.log(article.mainImage)}

        <div className="prose prose-lg max-w-none mb-8">
          <ReactMarkdown>{formatContent(article.content)}</ReactMarkdown>
        </div>

        {article.conclusion && (
          <div className="bg-gray-50 p-6 rounded-lg mb-12">
            <h2 className="text-2xl font-bold mb-4">Conclusion</h2>
            <p className="text-gray-700">{article.conclusion}</p>
          </div>
        )}
      </article>
    </div>
  );
};

export default ArticlePage; 