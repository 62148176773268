export const categories = [
  {
    id: 1,
    name: "Livraison à domicile",
    slug: "livraison-domicile",
    description: "Tout savoir sur la livraison à domicile"
  },
  {
    id: 2,
    name: "Points relais",
    slug: "points-relais",
    description: "Guide complet sur les points relais"
  },
  {
    id: 3,
    name: "E-commerce",
    slug: "e-commerce",
    description: "Solutions de livraison pour l'e-commerce"
  }
];

export const articles = [
  {
    id: 1,
    title: "Comment choisir le meilleur point relais pour vos livraisons",
    slug: "choisir-meilleur-point-relais",
    chapo: "Découvrez les critères essentiels pour sélectionner le point relais idéal pour vos colis",
    publishedAt: "2024-01-15",
    author: {
      name: "Marie Dubois",
      bio: "Experte en logistique urbaine avec plus de 15 ans d'expérience",
      role: "Directrice des Opérations chez LogiTech Solutions",
      credentials: "Master en Supply Chain Management, Certification Green Logistics",
      image: "https://placehold.co/400x400",
      linkedin: "https://linkedin.com/in/marie-dubois"
    },
    categories: [1, 2],
    tags: ["point relais", "livraison", "proximité"],
    readingTime: 8,
    mainImage: {
      url: "https://placehold.co/1200x800",
      alt: "Point relais moderne avec client"
    },
    content: `
# Comment choisir le meilleur point relais

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.

## Les critères essentiels

- Proximité avec votre domicile
- Horaires d'ouverture étendus
- Facilité d'accès
- Espace de stockage

## L'importance de la localisation

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.

## Les avantages des points relais

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.
    `,
    conclusion: "Choisir le bon point relais peut grandement faciliter la réception de vos colis."
  },
  {
    id: 2,
    title: "Les meilleures pratiques pour la livraison e-commerce",
    slug: "meilleures-pratiques-livraison-ecommerce",
    chapo: "Optimisez vos livraisons e-commerce avec nos conseils d'experts",
    publishedAt: "2024-01-20",
    author: {
      name: "Thomas Martin",
      bio: "Expert en e-commerce et stratégie digitale depuis 2010",
      role: "Consultant Senior E-commerce",
      credentials: "MBA E-Business, Google Digital Expert",
      image: "https://placehold.co/400x400",
      linkedin: "https://linkedin.com/in/thomas-martin"
    },
    categories: [3],
    tags: ["e-commerce", "optimisation", "livraison"],
    readingTime: 10,
    mainImage: {
      url: "https://placehold.co/1200x800",
      alt: "Livraison e-commerce"
    },
    content: `
# Les meilleures pratiques pour la livraison e-commerce

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.

## Optimiser les délais de livraison

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.

## La communication client

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor.
    `,
    conclusion: "Une bonne stratégie de livraison est essentielle pour la satisfaction client."
  }
]; 