import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ArticleCard from '../../components/blog/ArticleCard';
import { fetchArticles, fetchCategories } from '../../lib/strapi.js';

const BlogIndex = () => {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [articlesData, categoriesData] = await Promise.all([
          fetchArticles(),
          fetchCategories()
        ]);
        setArticles(articlesData);
        setCategories(categoriesData);
      } catch (err) {
        setError('Erreur lors du chargement des données');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);

  if (loading) return <div className="container mx-auto px-4 py-8">Chargement...</div>;
  if (error) return <div className="container mx-auto px-4 py-8 text-red-500">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <Helmet>
        <title>Blog - Conseils et actualités sur la livraison et les points relais</title>
        <meta 
          name="description" 
          content="Découvrez nos articles sur la livraison, les points relais et l'e-commerce. Conseils d'experts et actualités du secteur."
        />
      </Helmet>

      <div className="max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold mb-8">Blog</h1>
        
        {/* Catégories */}
        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">Catégories</h2>
          <div className="flex gap-4 flex-wrap">
            {categories.map((category) => (
              <Link
                key={category.id}
                to={`/blog/categorie/${category.slug}`}
                className="px-4 py-2 bg-gray-100 rounded-full hover:bg-primary hover:text-white transition-colors"
              >
                {category.name}
              </Link>
            ))}
          </div>
        </div>

        {/* Articles */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {articles.map((article) => (
            <ArticleCard key={article.id} article={article} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogIndex; 