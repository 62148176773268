import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from "./ui/button";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <header className="bg-white shadow-sm relative">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex-shrink-0">
            <Link to="/" className="text-2xl font-bold text-primary">
              Colis Facile
            </Link>
          </div>

          {/* Bouton menu burger */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 rounded-lg hover:bg-gray-100"
            aria-label="Menu principal"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMenuOpen ? (
                <path d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path d="M4 6h16M4 12h16M4 18h16" />
              )}
            </svg>
          </button>

          {/* Navigation desktop */}
          <nav className="hidden md:flex space-x-4">
            <Link to="/" className="text-gray-500 hover:text-gray-900">Accueil</Link>
            <Link to="/suivi" className="text-gray-500 hover:text-gray-900">Suivi de colis</Link>
            <Link to="/relay/search" className="text-gray-500 hover:text-gray-900">Point relais</Link>
            <Link to="/blog" className="text-gray-500 hover:text-gray-900">Blog</Link>
          </nav>
        </div>

        {/* Menu mobile */}
        {isMenuOpen && (
          <div className="md:hidden absolute top-full left-0 right-0 bg-white border-t border-gray-200 shadow-lg z-50">
            <nav className="flex flex-col space-y-2 p-4">
              <Link 
                to="/" 
                className="text-gray-500 hover:text-gray-900 py-2 px-4 rounded-lg hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                Accueil
              </Link>
              <Link 
                to="/suivi" 
                className="text-gray-500 hover:text-gray-900 py-2 px-4 rounded-lg hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                Suivi de colis
              </Link>
              <Link 
                to="/relay/search" 
                className="text-gray-500 hover:text-gray-900 py-2 px-4 rounded-lg hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                Point relais
              </Link>
              <Link 
                to="/blog" 
                className="text-gray-500 hover:text-gray-900 py-2 px-4 rounded-lg hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                Blog
              </Link>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
