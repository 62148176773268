import axios from 'axios';

export const strapiAPI = axios.create({
  baseURL: process.env.REACT_APP_STRAPI_URL || 'http://localhost:1337/api',
});

export const fetchArticles = async () => {
  try {
    const response = await strapiAPI.get('/articles', {
      params: {
        populate: '*'
      }
    });
    return response.data.data || [];
  } catch (error) {
    console.error('Erreur lors de la récupération des articles:', error);
    return [];
  }
};

export const fetchCategories = async () => {
  try {
    const response = await strapiAPI.get('/categories', {
      params: {
        populate: '*'
      }
    });
    return response.data.data || [];
  } catch (error) {
    console.error('Erreur lors de la récupération des catégories:', error);
    return [];
  }
}; 